import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'
import SkillCard from '../components/SkillCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={7}>
      <Hero offset={0}>
        <BigTitle>
          Hello, <br /> I'm Zach Angell.
        </BigTitle>
        <Subtitle>Generating value for companies through data science and machine learning.</Subtitle>
      </Hero>
      <About offset={1}>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="Zach Angell" />
          <AboutSub>
            Data Scientist | Machine Learning Engineer
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          I'm a data scientist who loves working on real world problems that catch my interest. A few things I've worked on: a complaint identification model for gathering business intelligence on Twitter, a sentiment detection model to identify profitable trading opportunities.
        </AboutDesc>
        <AboutDesc>
          I'm passionate about combining data science with my experience in Finance, Economics, and Software Development to generate valuable insights.
         </AboutDesc>
         <AboutDesc>
          Previously, I worked for several years at FIS, a financial technology company, and studied Economics and
          Computer Science at Boston College. I have also passed Level I and Level II of the Chartered Financial
          Analyst exams.
        </AboutDesc>
      </About>
      <Projects offset={2}>
        <Title>Projects</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="SocialMarketer"
            link="https://social-marketer.herokuapp.com/"
            bg="linear-gradient(to left, #D4145A 0%, #FBB03B 100%)"
          >
          A machine learning powered application for identifying social media complaints of your company's competitors.
          </ProjectCard>
          <ProjectCard
            title="Open Source Contributions"
            link="https://github.com/zangell44/open-source-contributions"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
          I am an active contributor to popular open source Python libraries. Let me know if you have a project that
          needs work!
          </ProjectCard>
          <ProjectCard
            title="Writing Samples"
            link="https://medium.com/@zachary.james.angell"
            bg="linear-gradient(to left, #662D8C 0%, #ED1E79 100%)"
          >
          Communication with colleagues and stakeholders is critical. Check out some of my writing samples on Medium.
          </ProjectCard>
          <ProjectCard
            title="Data Science Competitions"
            link="https://www.kaggle.com/zangell"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
          Find me and compete with me on Kaggle! I'm currently working on Jigsaw Unintended Bias in Toxicity
          Classification.
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <Projects offset={4}>
        <Title>Skills</Title>
        <ProjectsWrapper>
          <SkillCard
            title="Programming Languages"
            bg="linear-gradient(to right, #00FA9A 0%, #FBB03B 100%)"
          >
          Python (Pandas, Numpy, SciPy, Scikit-learn, Keras), SQL, Scala
          </SkillCard>
          <SkillCard
            title="Data Engineering"
            bg="linear-gradient(to right, #00FA9A 0%, #FBB03B 100%)"
          >
          Data Mining, Web Scraping, Data Cleaning, Feature Engineering
          </SkillCard>
          <SkillCard
            title="Databases and Technologies"
            bg="linear-gradient(to right, #00FA9A 0%, #FBB03B 100%)"
          >
          SQL, MongoDB, AWS (Lambda, Sagemaker, RDS), Apache Spark, Docker
          </SkillCard>
          <SkillCard
            title="Data Modeling"
            bg="linear-gradient(to right, #00FA9A 0%, #FBB03B 100%)"
          >
          Descriptive Statistics, Hypothesis Testing, KNN, Linear Regression, Logistic Regression, Survival Analysis,
          Quantile Regression, Bias-Variance Trade-off, Model Validation
          </SkillCard>
          <SkillCard
            title="Machine Learning and Advanced Prediction"
            bg="linear-gradient(to right, #00FA9A 0%, #FBB03B 100%)"
          >
          Unsupervised Learning, Natural Language Processing, Tree Ensembles, Neural Networks (TensorFlow, Keras)
          </SkillCard>
        </ProjectsWrapper>
      </Projects>
        <Contact offset={6}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
            <a href="mailto:zachangell1@gmail.com">Shoot me an email</a> or find me on other platforms:{' '}
            <a href="https://www.linkedin.com/in/zachangell/">LinkedIn</a> &{' '}
            <a href="https://github.com/zangell44/">GitHub</a>
          </ContactText>
        </Inner>
      </Contact>
        <Footer>
          2019 Zach Angell Data Science Portfolio.{' '}
          Theme by{' '}<a href="https://www.lekoarts.de">LekoArts</a>.
        </Footer>
    </Parallax>
  </>
)

export default Index
