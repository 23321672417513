const tailwind = require('../tailwind')

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: "Zach's Data Science Portfolio", // Navigation and Site Title
  siteTitleAlt: 'Zach Angell', // Alternative Site title for SEO
  siteTitleShort: 'Zach Angell', // short_name for manifest
  siteHeadline: 'Data Scientist', // Headline for schema.org JSONLD
  siteUrl: 'https://zach-angell.dev', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/z_logo.png', // Used for SEO and manifest
  siteDescription: "Zach Angell's Data Science Portfolio",
  author: 'LekoArts', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
//  userTwitter: '@cara', // Twitter Username
//  ogSiteName: 'cara', // Facebook Site Name
//  ogLanguage: 'en_US', // Facebook Language
  googleAnalyticsID: 'UA-130588896-2',

  // Manifest and Progress color
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue,
}
